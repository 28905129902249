import React from "react"
import SeeMoreButton from "../components/seeMoreButton"

const CreativeWorkSection = () => (
  <section className="section-wrapper bg-light-beige">
    <div className="section column-direction">
      <h2 className="section-title">
        <span role="img" aria-label="paint emoji" alt='paint emoji'>
          🎨
        </span>{" "}
        <span className="gradient-text">
          • I use coding as a creative medium
        </span>
      </h2>
      <h3 className="section-subtitle">
        You can find here my experiments with creative coding
      </h3>
      <div className="codepen-section">
        <div className="codepen-item">
          <img src="/street-light-css.png" alt='street light drawing with dark colors and contrast'/>
          <div className="codepen-item-cover">
            <a
              className="link"
              href="https://codepen.io/segolene-alquier/pen/dyYpKdN"
              target="_blank"
			  rel="noreferrer"
            >
              <span role="img" aria-label="eyes emoji" alt='eyes emoji'>👀  </span>See code on Codepen
            </a>
          </div>
        </div>
        <div className="codepen-item">
          <img src="/moonlight-css.png" alt='moonlight glowing drawing'/>
          <div className="codepen-item-cover">
            <a
              className="link"
              href="https://codepen.io/segolene-alquier/pen/gOaLpMW"
              target="_blank"
			  rel="noreferrer"
            >
              <span role="img" aria-label="eyes emoji" alt='eyes emoji'>👀  </span> See code on Codepen
            </a>
          </div>
        </div>
        <div className="codepen-item">
          <img src="/window-light-css.png" alt='window drawing in the dark with contrast'/>
          <div className="codepen-item-cover">
            <a
              className="link"
              href="https://codepen.io/segolene-alquier/pen/zYvoobG"
              target="_blank"
			  rel="noreferrer"
            >
              <span role="img" aria-label="eyes emoji" alt='eyes emoji'>👀  </span> See code on Codepen
            </a>
          </div>
        </div>
      </div>
      <SeeMoreButton
        cta="Check my other pens"
        link="https://codepen.io/segolene-alquier"
      />
    </div>
  </section>
)

export default CreativeWorkSection
