import React from "react"
import SeeMoreButton from "../components/seeMoreButton"

const ShortBioSection = () => (
  <section className="section-wrapper bg-lighter-beige">
    <div className="section column-direction">
      <p>
        I'm a former Product Manager who started to code in order to be able to
        understand and work with developers better. I loved coding and creating
        so much that I actually turned into one!
      </p>
      <p>I'm now working as a Software Engineer at Doctolib, where I have the chance to contribute to making healthcare more accessible and innovative!</p>
      <p>I can spend hours creating things with front-end technologies. What I love the most about code is the endless creation possibilities it offers!</p>
	  <SeeMoreButton cta="See the long story" link="/about" />
    </div>
  </section>
)

export default ShortBioSection
