import React from "react"

import SEO from "../components/seo"
import Navbar from "../components/navbar"
import HeroSection from "../components/heroSection"
import ShortBioSection from "../components/shortBioSection"
import RecommendedArticlesSection from "../components/recommendedArticlesSection"
import TalksSection from "../components/talksSection"
import CreativeWorkSection from "../components/creativeWorkSection"
import { Footer } from "../components/footer"

class IndexPage extends React.Component {
  render() {
    return (
      <>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Navbar />
        <HeroSection />
        <ShortBioSection />
        <RecommendedArticlesSection />
        <TalksSection />
        <CreativeWorkSection />
        <Footer />
      </>
    )
  }
}

export default IndexPage
