import React from "react"

const TalksSection = () => (
  <section className="section-wrapper bg-lighter-beige">
    <div className="section column-direction">
      <h2 className="section-title">
        <span role="img" aria-label="mic emoji">
          🎤
        </span>{" "}
        <span className="gradient-text">• I'm talkative too</span>
      </h2>
      <h3 className="section-subtitle">
        You can find here podcasts I was invited in or talks I gave
      </h3>
	  <div className="podcast-wrapper">
		<iframe 
			src="https://open.spotify.com/embed/episode/65sjJyUhjrWeWYhpxy9EQO?utm_source=generator" 
			width="100%" 
			height="232" 
			frameBorder="0" 
			title="DevJourney podcast episode" 
			allow="encrypted-media;"
			loading="lazy"
		></iframe>
	  </div>
	  <div className="video-wrapper">
	  	<iframe 
		  className="responsive-iframe"
		  width="100%" 
		  height="auto" 
		  src="https://www.youtube.com/embed/XUzEU37Vuk4" 
		  title="YouTube video CSS art" 
		  frameBorder="0" 
		  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
		  allowFullScreen
		  loading="lazy"
		  ></iframe>
	  </div>
      <div className="podcast-wrapper">
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/4cdJfOhuO5K8VOIemqEeYN"
          width="100%"
          height="232"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
		  title="Refactor podcast episode"
		  loading="lazy"
        ></iframe>
      </div>
      <div className="podcast-wrapper">
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/3XSPiNQERMP5bOKf22Bx4W"
          width="100%"
          height="232"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
		  title="Docstring podcast episode"
		  loading="lazy"
        ></iframe>
      </div>
      <div className="podcast-wrapper">
        <iframe
          allow="autoplay *; encrypted-media *; fullscreen *"
          frameBorder="0"
          height="175"
          width="100%"
          src="https://embed.podcasts.apple.com/fr/podcast/femmes-dans-la-tech-%C3%A9pisode-2-s%C3%A9gol%C3%A8ne-alquier-d%C3%A9veloppeuse/id1556274432?i=1000512393383"
		  title="Pole-emploi podcast episode"
		  loading="lazy"
        ></iframe>
      </div>
    </div>
  </section>
)

export default TalksSection
