import React from "react"
import SocialMediaLinks from "./socialMediaLinks"

const HeroSection = () => (
  <section className="section-wrapper bg-light-beige">
    <div className="section hero-section row-direction">
      <div className="hero-section-text">
        <h1>
          <span className="gradient-text">I am Ségolène </span>
          <span role="img" aria-label="emoji wave hand">
            👋
          </span>
        </h1>
        <h2>Welcome to my corner of the internet!</h2>
        <h3 className="hero-section-description">
          I'm a fullstack developer with a strong interest in creative coding,
          design & Tech inclusion. <br />
        </h3>
        <SocialMediaLinks />
      </div>
      <img src="/profile-picture.jpg" className="hero-section-picture" alt="hero section portrait"></img>
    </div>
  </section>
)

export default HeroSection
