import React from "react"
import { Link } from "gatsby"
import SeeMoreButton from "../components/seeMoreButton"

const RecommendedArticlesSection = () => {
  const posts = [
    { id: 1, title: "How to make great code reviews? ", link: "/blog/how-to-make-great-code-reviews", description: "Why they matter, how to ensure they are done well and how they serve both the team and the product quality." },
    { id: 2, title: "Make your 1st contribution to open-source", link: "/blog/make-your-first-pull-request-to-an-open-source-project", description: "Does the world of open-source sound both mysterious and appealing to you? You're not alone!" },
    { id: 3, title: "How to reduce back-and-forths on a pull request", link: "/blog/how-to-reduce-back-and-forths-on-a-pull-request", description: "Having your code reviewed is a great opportunity to improve but it can also be very time-consuming at the beginning." },
  ]
  return (
    <section className="section-wrapper bg-light-beige">
      <div className="section column-direction">
        <h2 className="section-title">
          <span role="img" aria-label="pen emoji">
            ✏️
          </span>{" "}
          <span className="gradient-text">• I love to write</span>
        </h2>
        <h3 className="section-subtitle">
          You can find here my most read articles
        </h3>
        <div className="recommended-posts-wrapper">
          {posts.map(post => {
            return (
              <div key={post.id} className="blog-card blog-card-recommendation">
                <Link to={post.link}>
                  <div className="blog-card-tag">Code</div>
                  <h3 className="blog-card-title">{post.title}</h3>
                  <p
                    className="blog-card-content"
                    dangerouslySetInnerHTML={{
                      __html: post.description,
                    }}
                  />
                </Link>
              </div>
            )
          })}
        </div>
        <SeeMoreButton cta="Check the other articles" link="/blog" />
      </div>
    </section>
  )
}

export default RecommendedArticlesSection
